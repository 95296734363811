import {
  createSetFiltersAction,
  createSetPageAction,
} from 'shared/helpers/create-set-options-actions';
import { isNil, pickBy } from 'lodash';

import { Request } from 'shared/tools/request';
import { createAction } from 'redux-actions';
import { createSuccessAction } from 'shared/helpers/create-success-action';
import { getCleaners } from 'cleaning-lots/actions/cleaners';
import { normalizeRows } from '../normalization/admin-cleaning-schema';
import { notification } from 'antd';
import queryString from 'query-string';

const queryParamsSelector = state => queryString.parse(state.router.location.search);

const isNotNil = value => !isNil(value);

const isNotEmptyStr = value => value !== '';

const openNotification = (type, message, description) => {
  notification[type]({ message, description });
};

export const setSelectedAuctions = createAction('SET_SELECTED_AUCTIONS', (selectedIds = []) => ({
  selectedIds,
}));

export const setFilters = createSetFiltersAction('SET_FILTERS_ADMIN_CLEANING');

export const setPage = createSetPageAction('SET_PAGE_ADMIN_CLEANING');

export const setToInitialState = createAction('RAW_AUCTION_SET_TO_INITIAL_STATE');

export const getAuctionRequest = createAction('RAW_AUCTION_REQUEST');
export const getAuctionSuccess = createSuccessAction(
  'RAW_AUCTION_SUCCESS',
  (items, rows, count) => ({
    items,
    rows,
    count,
  }),
);
export const getAuctionError = createAction('RAW_AUCTION_ERROR');

const getQueryFilters = filters => pickBy(pickBy(filters, isNotNil), isNotEmptyStr);

const getAuctionsApiCall = (isAdmin, params) =>
  isAdmin
    ? Request.get('/api/raw-auction', { params })
    : Request.get('/api/raw-auction/cleaner-auctions', { params });

export const getAuction = () => async (dispatch, getState) => {
  dispatch(getAuctionRequest());
  const { auctions, cleaners, user } = getState();
  const { offset, filters, sortBy, sortDir } = auctions;

  const isAdmin = user.userInfo.role.name === 'admin';
  const queryFilters = getQueryFilters(filters);
  const params = {
    ...queryFilters,
    offset,
    sortBy,
    sortDir,
  };

  console.log("[getAuction] params", params);

  try {
    const { data } = await getAuctionsApiCall(isAdmin, params);

    if (!cleaners.count && isAdmin) {
      await dispatch(getCleaners());
    }

    const {
      entities: { items },
      result: { rows, count },
    } = normalizeRows(data);
    dispatch(getAuctionSuccess(items, rows, count));
  } catch (err) {
    dispatch(getAuctionError());
  }
};

export const getFullAuctionsListRequest = createAction('GET_FULL_AUCTIONS_LIST_REQUEST');
export const getFullAuctionsListSuccess = createSuccessAction(
  'GET_FULL_AUCTIONS_LIST_SUCCESS',
  fullList => fullList,
);
export const getFullAuctionsListError = createAction('GET_FULL_AUCTIONS_LIST_ERROR');

export const getFullAuctionsList = () => async dispatch => {
  dispatch(getFullAuctionsListRequest());

  try {
    const { data } = await Request.get('/api/raw-auction/list-all');

    dispatch(getFullAuctionsListSuccess(data));
  } catch (err) {
    openNotification('error', 'Get auction list failed, please reload the page');
    dispatch(getFullAuctionsListError());
  }
};

export const sendLotsActionRequest = createAction('PUBLISH_LOTS_REQUEST');
export const sendLotsActionSuccess = createSuccessAction('PUBLISH_LOTS_SUCCESS');
export const sendLotsActionError = createAction('PUBLISH_LOTS_ERROR');

export const unpublishLots = ids => async dispatch => {
  dispatch(sendLotsActionRequest());
  try {
    await Request.put('/api/raw-auction/unpublish', { data: { ids } });

    dispatch(sendLotsActionSuccess());
    openNotification('success', 'Lots were successfully unpublished');
  } catch (err) {
    dispatch(sendLotsActionError());
    openNotification('error', 'Error has been occurred while unpublishing lots');
  }
};

// export const addCustomLot = lotFields => async dispatch => {
//   dispatch(sendLotsActionRequest());
//   try {
//     await Request.put('/api/raw-auction/unpublish', { data: { lotFields } });
//
//     dispatch(sendLotsActionSuccess());
//     openNotification('success', 'Lot were successfully added');
//   } catch (err) {
//     dispatch(sendLotsActionError());
//     openNotification('error', 'Error has been occurred while adding new lot');
//   }
// };

export const ignoreLots = ids => async dispatch => {
  dispatch(sendLotsActionRequest());
  try {
    await Request.put('/api/raw-auction/ignore', { data: { ids } });

    dispatch(sendLotsActionSuccess());
    dispatch(getAuction());
    openNotification('success', 'Lots were successfully ignored');
  } catch (err) {
    dispatch(sendLotsActionError());
    openNotification('error', 'Error has been occurred while ignoring lots');
  }
};

export const finishCleaning = ids => async dispatch => {
  try {
    await Request.put('/api/raw-auction/finish-cleaning', { data: { ids } });

    dispatch(getAuction());
    openNotification('success', 'Cleaning was successfully finished');
  } catch (err) {
    openNotification('error', 'Error has been occurred while finishing cleaning');
  }
};

export const deleteAuctions = ids => async dispatch => {
  try {
    await Request.put('/api/raw-auction/delete', { data: { ids } });
    dispatch(getAuction());
    openNotification('success', 'Deleted');
  } catch (err) {
    openNotification('error', 'Error');
  }
};

export const assignCleaner = (ids, cleanerId = null) => async (dispatch, getState) => {
  dispatch(sendLotsActionRequest());
  const cleaner = getState().cleaners.items[cleanerId];
  const cleanerFullName = cleaner ? `${cleaner.name} ${cleaner.surname}` : 'Admin or Undefined';

  try {
    const data = { ids, cleaner_id: cleanerId };
    await Request.put('/api/raw-auction/assign-cleaner', { data });

    dispatch(sendLotsActionSuccess());
    dispatch(getAuction());
    openNotification('success', `${cleanerFullName} was successfully assigned as cleaner`);
  } catch (err) {
    dispatch(sendLotsActionError());
    openNotification('error', 'Error has been occurred while assigning cleaner.');
  }
};

export const updateAuctionRequest = createAction('UPDATE_AUCTION_REQUEST');
export const updateAuctionSuccess = createAction('UPDATE_AUCTION_SUCCESS');
export const updateAuctionError = createAction('UPDATE_AUCTION_ERROR');

export const updateAuction = (id, data) => async dispatch => {
  dispatch(updateAuctionRequest());
  try {
    const resp = await Request.put(`/api/raw-auction/${id}`, { data });

    if (resp.data && resp.data.error) {
      dispatch(updateAuctionError());
      openNotification('error', resp.data.error);
    } else {
      dispatch(getAuction());
      dispatch(updateAuctionSuccess());
    }
  } catch (err) {
    dispatch(updateAuctionError());
  }
};

export const openEditModal = createAction('OPEN_EDIT_AUCTION_MODAL');
export const closeEditModal = createAction('CLOSE_EDIT_AUCTION_MODAL');

export const editPriority = (ids, priority) => async dispatch => {
  dispatch(updateAuctionRequest());
  try {
    const data = { ids, priority };
    await Request.put('/api/raw-auction/edit-priority', { data });

    dispatch(closeEditModal());
    dispatch(getAuction());
    dispatch(updateAuctionSuccess());
  } catch (err) {
    dispatch(updateAuctionError());
  }
};

export const setSortDirection = createAction('SET_SORT_DIRECTION_AUCTIONS', sortDir => ({
  sortDir,
}));
export const setSortBy = createAction('SET_SORT_BY_AUCTIONS', sortBy => ({ sortBy }));

export const setAuctionHouse = createAction('SET_AUCTION_HOUSE', auctionHouseId => ({
  auctionHouseId,
}));

export const setCreatedDateFrom = createAction('SET_CREATED_DATE_FROM', createdDateFrom => ({
  createdDateFrom,
}));

export const setCreatedDateTo = createAction('SET_CREATED_DATE_TO', createdDateTo => ({
  createdDateTo,
}));

export const setPublishedDateFrom = createAction('SET_PUBLISHED_DATE_FROM', publishedDateFrom => ({
  publishedDateFrom,
}));

export const setPublishedDateTo = createAction('SET_PUBLISHED_DATE_TO', publishedDateTo => ({
  publishedDateTo,
}));

export const setAuctionStatus = createAction('SET_AUCTION_STATUS', auctionStatus => ({
  auctionStatus,
}));

export const setAuctionFlag = createAction('SET_AUCTION_FLAG', auctionFlag => ({
  auctionFlag,
}));

export const setAuctionDateFrom = createAction('SET_AUCTION_DATE_FROM', auctionDateFrom => ({
  auctionDateFrom,
}));

export const setAuctionDateTo = createAction('SET_AUCTION_DATE_TO', auctionDateTo => ({
  auctionDateTo,
}));

export const loadInitialData = () => async (dispatch, getState) => {
  const {
    auctionHouseId,
    createdDateFrom,
    createdDateTo,
    publishedDateFrom,
    publishedDateTo,
    auctionStatus,
    auctionFlag,
    auctionDateFrom,
    auctionDateTo,
  } = queryParamsSelector(getState());

  dispatch(setAuctionHouse(auctionHouseId));
  dispatch(setCreatedDateFrom(createdDateFrom));
  dispatch(setCreatedDateTo(createdDateTo));
  dispatch(setPublishedDateFrom(publishedDateFrom));
  dispatch(setPublishedDateTo(publishedDateTo));
  dispatch(setAuctionStatus(auctionStatus !== undefined ? auctionStatus : 'unreviewed'));
  dispatch(setAuctionFlag(auctionFlag));
  dispatch(setAuctionDateFrom(auctionDateFrom));
  dispatch(setAuctionDateTo(auctionDateTo));
};
